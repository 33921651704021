import {
  platformAdapter,
  PlatformRecommendationsRequest,
  PlatformResult,
  recommendationsRequestSchema,
  resultSchema
} from '@empathyco/x-adapter-platform';
import { RecommendationsRequest, Result } from '@empathyco/x-types';

export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends PlatformResult {
  description: string;
  collection: string;
  brand: string;
  availableDate: string;
  urlLogoImage: string;
  urlHoverImage: string;
}

declare module '@empathyco/x-types' {
  export interface Result {
    collection: string;
    description: string;
    brand: string;
    availableDate: string;
    urlLogoImage: string;
    urlHoverImage: string;
  }
}

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  description: 'description',
  collection: 'collection',
  brand: 'brand',
  urlLogoImage: 'urlLogoImage',
  urlHoverImage: 'urlHoverImage',
  images: ({ __images, urlHoverImage }) => __images.concat(urlHoverImage),
  price: ({ __prices: rawPrices }) => {
    if (rawPrices) {
      return {
        value: rawPrices.current.value,
        originalValue: rawPrices.previous?.value ?? rawPrices.current.value,
        futureValue: rawPrices.future?.value ?? rawPrices.current.value,
        hasDiscount:
          rawPrices.current.value < (rawPrices.previous?.value ?? rawPrices.current.value)
      };
    }
    return undefined;
  },
  availableDate: 'availableDate'
});

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
});
