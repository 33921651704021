export default {
  EUR: 'i.iii,dd €',
  USD: '$ i,iii.dd',
  CHF: 'CHF i.iii,dd',
  GBP: '£ i,iii.dd',
  PLN: 'i.iii,dd zł',
  DKK: 'i.iii,dd kr',
  SEK: 'i.iii,dd kr',
  CZK: 'i.iii,dd Kč',
  CLP: '$ i.iii.dd',
  COP: '$ i.iii.dd',
  NOK: 'i.iii,dd kr',
  MXN: '$ i,iii.dd',
  PEN: 'S/ i,iii.dd',
  CAD: 'C$ i,iii.dd',
  BRL: 'R$ i.iii,dd',
  ILS: '₪ i,iii.dd',
  RON: 'i.iii,dd lei'
} as Record<string, string>;
